"use client";

import { useEffect } from "react";
import { useAnalytics } from "use-analytics";

import { useUser } from "~/components/queries/useUser";

export default function AnalyticsIdentifier({
  children,
}: {
  children: React.ReactNode;
}) {
  const { user } = useUser();
  const analytics = useAnalytics();
  useEffect(() => {
    user &&
      void analytics.identify(user.id, {
        name: user.name,
        email: user.email,
        role: user.roleName,
        organisation: user.organisation,
      });
  }, [analytics, user]);

  return children;
}
