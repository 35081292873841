import { useQuery } from "@tanstack/react-query";

import { USER_STALE_TIME } from "~/client/constants";
import { getUser } from "~/server/actions/data/getUser";

export function useUser(enabled = true, poll: number | boolean = false) {
  const q = useQuery({
    queryKey: ["user"],
    queryFn: () => getUser(),
    staleTime: USER_STALE_TIME,
    enabled,
    refetchInterval: poll
      ? typeof poll === "number"
        ? poll
        : USER_STALE_TIME
      : false,
  });

  return {
    ...q,
    user: q.data,
  };
}
