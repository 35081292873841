"use client";

import mixpanelPlugin from "@analytics/mixpanel";
import Analytics from "analytics";

import { env, host, isClient, isDev } from "~/env";

const plugins =
  env.NEXT_PUBLIC_MIXPANEL_TOKEN && host && isClient
    ? [
        mixpanelPlugin({
          token: env.NEXT_PUBLIC_MIXPANEL_TOKEN,
          customScriptSrc: host + "/a/lib.min.js",
          options: {
            ignore_dnt: true,
            api_host: host + "/a",

            // work around unnecessary redirects
            api_routes: {
              engage: "engage",
              groups: "groups",
              track: "track",
            },
          },
        }) as unknown as Record<string, unknown>,
      ]
    : [];

export const analytics = Analytics({
  app: "Insights",
  plugins,
  debug: isDev,
});
