"use client";

import { usePathname } from "next/navigation";
import { useEffect } from "react";
import { useAnalytics } from "use-analytics";

export function AnalyticsPageTracker({
  children,
}: {
  children: React.ReactNode;
}) {
  const pathname = usePathname();
  const analytics = useAnalytics();
  useEffect(() => {
    void analytics.page();
  }, [analytics, pathname]);

  return children;
}
