import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

const runtimeType =
  process.env.NEXT_RUNTIME ??
  process.env.NEXT_PUBLIC_NEXT_RUNTIME ??
  (typeof window !== "undefined"
    ? "browser"
    : process.versions.bun
      ? "bun"
      : process.versions.node
        ? "node"
        : "unknown");
const runtimeVersion =
  runtimeType === "browser"
    ? window.navigator.userAgent
    : runtimeType === "bun"
      ? process.versions.bun
      : runtimeType === "node"
        ? process.versions.node
        : "unknown";

const isEdgeRuntime = runtimeType === "edge";
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
const isClient = !!(typeof window === "object" && window);

const isTest = process.env.NODE_ENV === "test";
const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
const isVercelDev =
  process.env.NEXT_PUBLIC_VERCEL_ENV === "development" ||
  process.env.NEXT_PUBLIC_VERCEL_ENV === "preview" ||
  process.env.VERCEL_ENV === "development" ||
  process.env.VERCEL_ENV === "preview";
const gitCommitRef =
  process.env.VERCEL_GIT_COMMIT_REF ??
  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF ??
  "";

const hostName = isDev
  ? "localhost"
  : (process.env.APP_DOMAIN ??
    process.env.NEXT_PUBLIC_APP_DOMAIN ??
    process.env.NEXT_PUBLIC_VERCEL_URL ?? // NOTE: vercel_url points to a git ref (i.e. a commit), which might be different to staging sticky domain
    process.env.VERCEL_URL ??
    "localhost");

const host = isDev ? `http://${hostName}:3000` : `https://${hostName}`;

const envName = isDev
  ? "development"
  : isVercelDev
    ? gitCommitRef === "main"
      ? "staging"
      : "preview"
    : "production";

const isStaging = envName === "staging";
const sha =
  process.env.APP_VERSION ?? process.env.VERCEL_GIT_COMMIT_SHA ?? "unknown";
const version = sha.substring(0, 8);
const debugMode = isDev || isTest;

export const env = createEnv({
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars.
   */
  server: {
    DATABASE_URL: z.string().url(),
    NODE_ENV: z
      .enum(["development", "test", "production"])
      .default("development"),
    // UPLOADTHING_SECRET: z.string().min(1),
    // UPLOADTHING_APP_ID: z.string().min(1),
    TRANSLOADIT_AUTH_SECRET: z.string().min(1),
    TRANSLOADIT_NOTIFICATION_URL: z
      .string()
      .min(1)
      .optional()
      .default(`${host}/api/transloadit`),
    INNGEST_EVENT_KEY: z.string().min(1),
    INNGEST_SIGNING_KEY: z.string().min(1),
    B2_APPLICATION_KEY_ID: z.string().min(1),
    B2_APPLICATION_KEY: z.string().min(1),
    B2_BUCKET_ID: z.string().min(1),
    B2_BUCKET_NAME: z.string().min(1),
    LOGTAIL_TOKEN: z.string().optional(),
    LOG_LEVEL: z.string().optional(),
    REDIS_URL: z.string().url(),
    UPSTASH_REDIS_REST_URL: z.string().url().optional(),
    UPSTASH_REDIS_REST_TOKEN: z.string().min(1).optional(),
    WORKER_REDIS_URL: z.string().url(),
    GOOGLE_API_KEY: z.string().min(1).optional(),
    OPENAI_API_KEY: z.string().min(1).optional(),
    OPENAI_API_KEYS: z.string().min(1).optional(),
    CAPSOLVER_API_KEY: z.string().min(1).optional(),
    PUPPETEER_EXECUTABLE_PATH: z.string().min(1).optional(),
    PUPPETEER_PROXY: z.string().min(1).optional(),
    WORKER_CONCURRENCY: z
      .string()
      .min(1)
      .default("10")
      .transform((v) => parseInt(v)),
    IS_WORKER: z.string().min(1).optional(),
    GVG_LOGIN: z.string().min(1).optional(),
    GVG_PASSWORD: z.string().min(1).optional(),
    NEXT_RUNTIME: z.enum(["nodejs", "edge"]).default("nodejs"),
    TELEMETRY_SH_KEY: z.string().min(1).optional(),
    CLERK_SECRET_KEY: z.string().min(1),
    CLERK_DEBUG: z.boolean().optional().default(false),
  },

  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars. To expose them to the client, prefix them with
   * `NEXT_PUBLIC_`.
   */
  client: {
    NEXT_PUBLIC_TRANSLOADIT_AUTH_KEY: z.string().min(1),
    NEXT_PUBLIC_TRANSLOADIT_UPLOAD_TEMPLATE_ID: z.string().min(1),
    NEXT_PUBLIC_LOGTAIL_TOKEN: z.string().optional(),
    NEXT_PUBLIC_LOG_LEVEL: z.string().optional(),
    NEXT_PUBLIC_GOOGLE_API_KEY: z.string().min(1),
    NEXT_PUBLIC_MIXPANEL_TOKEN: z.string().min(1).optional(),
    NEXT_PUBLIC_VERCEL_URL: z.string().min(9).optional(),
    NEXT_PUBLIC_MUI_X_LICENSE_KEY: z.string().min(1),
    NEXT_PUBLIC_DEMO_SESSION_ID: z.string().uuid().min(1),
    NEXT_PUBLIC_APP_DOMAIN: z.string().min(1).optional(),
    NEXT_PUBLIC_CONTACT_US_HUBSPOT_FORM_ID: z.string().min(1),
    NEXT_PUBLIC_HUBSPOT_PORTAL_ID: z.string().min(1),
    NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: z.string().min(1),
  },

  /**
   * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
   * middlewares) or client-side so we need to destruct manually.
   */
  runtimeEnv: {
    DATABASE_URL: process.env.DATABASE_URL,
    NODE_ENV: process.env.NODE_ENV,
    // UPLOADTHING_APP_ID: process.env.UPLOADTHING_APP_ID,
    // UPLOADTHING_SECRET: process.env.UPLOADTHING_SECRET,
    TRANSLOADIT_AUTH_SECRET: process.env.TRANSLOADIT_AUTH_SECRET,
    NEXT_PUBLIC_TRANSLOADIT_UPLOAD_TEMPLATE_ID:
      process.env.NEXT_PUBLIC_TRANSLOADIT_UPLOAD_TEMPLATE_ID,
    NEXT_PUBLIC_TRANSLOADIT_AUTH_KEY:
      process.env.NEXT_PUBLIC_TRANSLOADIT_AUTH_KEY,
    TRANSLOADIT_NOTIFICATION_URL: process.env.TRANSLOADIT_NOTIFICATION_URL,
    INNGEST_EVENT_KEY: process.env.INNGEST_EVENT_KEY,
    INNGEST_SIGNING_KEY: process.env.INNGEST_SIGNING_KEY,
    B2_APPLICATION_KEY_ID: process.env.B2_APPLICATION_KEY_ID,
    B2_APPLICATION_KEY: process.env.B2_APPLICATION_KEY,
    B2_BUCKET_ID: process.env.B2_BUCKET_ID,
    B2_BUCKET_NAME: process.env.B2_BUCKET_NAME,
    LOGTAIL_TOKEN: process.env.LOGTAIL_TOKEN,
    NEXT_PUBLIC_LOGTAIL_TOKEN: process.env.NEXT_PUBLIC_LOGTAIL_TOKEN,
    LOG_LEVEL: process.env.LOG_LEVEL,
    NEXT_PUBLIC_LOG_LEVEL: process.env.NEXT_PUBLIC_LOG_LEVEL,
    REDIS_URL: process.env.REDIS_URL,
    UPSTASH_REDIS_REST_URL: process.env.UPSTASH_REDIS_REST_URL,
    UPSTASH_REDIS_REST_TOKEN: process.env.UPSTASH_REDIS_REST_TOKEN,
    WORKER_REDIS_URL: process.env.WORKER_REDIS_URL,
    GOOGLE_API_KEY: process.env.GOOGLE_API_KEY,
    OPENAI_API_KEY: process.env.OPENAI_API_KEY,
    OPENAI_API_KEYS: process.env.OPENAI_API_KEYS,
    CAPSOLVER_API_KEY: process.env.CAPSOLVER_API_KEY,
    PUPPETEER_EXECUTABLE_PATH: process.env.PUPPETEER_EXECUTABLE_PATH,
    PUPPETEER_PROXY: process.env.PUPPETEER_PROXY,
    WORKER_CONCURRENCY: process.env.WORKER_CONCURRENCY,
    IS_WORKER: process.env.IS_WORKER,
    GVG_LOGIN: process.env.GVG_LOGIN,
    GVG_PASSWORD: process.env.GVG_PASSWORD,
    NEXT_PUBLIC_GOOGLE_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
    NEXT_PUBLIC_MIXPANEL_TOKEN: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
    NEXT_PUBLIC_VERCEL_URL: process.env.NEXT_PUBLIC_VERCEL_URL,
    NEXT_RUNTIME: process.env.NEXT_RUNTIME,
    NEXT_PUBLIC_DEMO_SESSION_ID: process.env.NEXT_PUBLIC_DEMO_SESSION_ID,
    NEXT_PUBLIC_MUI_X_LICENSE_KEY: process.env.NEXT_PUBLIC_MUI_X_LICENSE_KEY,
    NEXT_PUBLIC_APP_DOMAIN: process.env.NEXT_PUBLIC_APP_DOMAIN,
    NEXT_PUBLIC_CONTACT_US_HUBSPOT_FORM_ID:
      process.env.NEXT_PUBLIC_CONTACT_US_HUBSPOT_FORM_ID,
    NEXT_PUBLIC_HUBSPOT_PORTAL_ID: process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID,
    TELEMETRY_SH_KEY: process.env.TELEMETRY_SH_KEY,
    NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY:
      process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY,
    CLERK_SECRET_KEY: process.env.CLERK_SECRET_KEY,
    CLERK_DEBUG: process.env.CLERK_DEBUG,
  },
  /**
   * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation.
   * This is especially useful for Docker builds.
   */
  skipValidation: !!process.env.SKIP_ENV_VALIDATION,
});

export {
  debugMode,
  envName,
  host,
  hostName,
  isClient,
  isDev,
  isEdgeRuntime,
  isStaging,
  isTest,
  isVercelDev,
  runtimeType as runtime,
  runtimeVersion,
  sha,
  version,
};
