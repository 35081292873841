import { createTheme } from "@mui/material";
import { Inter, Roboto_Mono } from "next/font/google";

const inter = Inter({ subsets: ["latin"] });
const robotoMono = Roboto_Mono({ subsets: ["latin-ext"], weight: "400" });

export const sharedThemeConfig = {
  typography: {
    fontFamily: inter.style.fontFamily,
    subtitle2: {
      fontSize: 12,
      fontWeight: 700,
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
    },
    h7: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: "120%",
    },
    bigFigure: {
      fontFamily: robotoMono.style.fontFamily,
      fontSize: 20,
      fontWeight: 400,
      letterSpacing: "-0.02em",
      wordSpacing: "-0.2em",
      lineHeight: "120%",
    },
  },
  shape: {
    borderRadius: 8,
  },
};

export const roevLight = createTheme({
  ...sharedThemeConfig,
  palette: {
    mode: "light",
    text: {
      primary: "#1f2937",
      secondary: "#727274",
      disabled: "#00000061",
    },
    primary: {
      main: "#4B5563",
      dark: "#111827",
      light: "#58585b",
      contrastText: "#F6F6f7",
    },
    secondary: {
      main: "#9CA3AF",
      dark: "#6B7280",
      light: "#D1D5DB",
      contrastText: "#27272a",
    },
    // warning: {
    //   // main: "#ed9f02",
    //   // dark: "# v",
    //   // light: "#f7e4b8",
    //   // contrastText: "#27272a",
    // },
    background: {
      default: "#f8f9fa",
    },
    divider: "#dfdfe0",
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        standardWarning: {
          backgroundColor: "#f7e4b8", // Set your desired color
          color: "#4B5563", // Adjust text color if needed
        },
      },
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: "card" },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.background.paper,
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: theme.palette.divider,
            borderRadius: theme.shape.borderRadius,
            // boxShadow:
            //   "0px 1.726396918296814px 7.7687859535217285px 0px rgba(75, 70, 92, 0.05)",
          }),
        },
      ],
    },
  },
});

export const roevDark = createTheme({
  ...sharedThemeConfig,
  palette: {
    mode: "dark",
    text: {
      primary: "#f6f6f7",
      secondary: "#cbd5e1",
      disabled: "#ffffff61",
    },
    primary: {
      main: "#f4f4f5",
      dark: "#f1f5f9",
      light: "#475569",
      contrastText: "#18181b",
    },
    secondary: {
      main: "#27272a",
      dark: "#18181b",
      light: "#3f3f46",
      contrastText: "#f6f6f7",
    },
    background: {
      default: "#1f2937",
    },
    divider: "#3f3f46",
  },
});

export const coolBgGradient =
  "linear-gradient(137deg, #39617A 12.98%, #080B29 112.7%)";
