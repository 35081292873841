"use client";

import {
  isServer,
  QueryClient,
  QueryClientProvider as QCP,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { DEFAULT_WIDGET_DATA_STALE_TIME } from "~/client/constants";

function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1,
        staleTime: DEFAULT_WIDGET_DATA_STALE_TIME,
        refetchIntervalInBackground: false,
      },
    },
  });
}

let browserQueryClient: QueryClient | undefined = undefined;

function getQueryClient() {
  if (isServer) {
    // Server: always make a new query client
    return makeQueryClient();
  } else {
    // Browser: make a new query client if we don't already have one
    // This is very important, so we don't re-make a new client if React
    // suspends during the initial render. This may not be needed if we
    // have a suspense boundary BELOW the creation of the query client
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
}

export function QueryClientProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const client = getQueryClient();

  return (
    <QCP client={client}>
      <ReactQueryDevtools
        buttonPosition="bottom-right"
        initialIsOpen={false}
        position="left"
      />
      {children}
    </QCP>
  );
}
