export const HOME_PAGE = "/";
export const URL_ERROR_PAGE = "/help/error";
export const URL_FORBIDDEN_PAGE = "/help/forbidden";
export const DASHBOARD_PAGE = "/dashboard";
export const EV_SCENARIO_BUILDER_PAGE =
  "/dashboard/:sessionId/report/scenario-builder";
export const INVITE_PAGE = "/invite/:sessionId/:inviteKey";

export const USER_LOGIN_PAGE = "/user/login";
export const USER_SIGNUP_PAGE = "/user/signup";
export const USER_LOGOUT_PAGE = "/user/logout";
export const USER_RESTART_PAGE = "/user/restart";
export const USER_CREATE_ORG_PAGE = "/user/create-org";
export const USER_LOGIN_PAGE_THEN_DASHBOARD = `${USER_LOGIN_PAGE}?redirect_url=/dashboard`;
export const USER_CHOOSE_ORG_PAGE = "/user/choose-org";

export const TRANSLOADIT_SIGNATURE_MAX_AGE = 10 * 60 * 60 * 1000; // 10 hours, must match server-side constant

export const DEFAULT_INTERACTIVE_POLL_TIME = 3000; // for when we want to show near real-time status (like processing fuel cards)
export const DEFAULT_WIDGET_DATA_STALE_TIME = 10 * 60 * 1000; // 10 mins
export const ICE_DASHBOARD_PROCESSING_REFRESH_INTERVAL = 20000;
export const ICE_DASHBOARD_SETTLED_REFRESH_INTERVAL = 12 * 60 * 60 * 1000; // 12h
export const EV_DASHBOARD_PROCESSING_REFRESH_INTERVAL = 20000;
export const SESSION_STALE_TIME = 10 * 60 * 1000; // 10 mins
export const USER_STALE_TIME = 10 * 60 * 1000; // 10 mins

export const ChargerTypes = {
  Level1: {
    name: "1.8kW (L1)",
    description:
      "A portable charger compatible with standard 3-pin power outlets, representing the most cost-effective but slowest charging option at 1.8kW",
    maxPower: 1800,
    capex: 1000,
  },
  Level2SinglePhase: {
    name: "7.4kW (L2)",
    description:
      "A permanently installed AC charger featuring a Type 2 (CCS) cable, designed for single-phase circuits and capable of charging at rates up to 7.4kW",
    maxPower: 7400,
    capex: 2000,
  },
  Level2ThreePhaseAt11: {
    name: "11kW (L2, 1ph)",
    description:
      "A permanently installed AC charger featuring a Type 2 (CCS) cable, connected to a three-phase circuit and capable of charging at rates up to 11kW",
    maxPower: 11000,
    capex: 3000,
  },
  // Level2ThreePhaseAt22: {
  //   name: "22W (L2, 1ph)",
  //   description:
  //     "A permanently installed AC charger featuring a Type 2 (CCS) cable, connected to a three-phase circuit and capable of charging at rates up to 22W",
  //   maxPower: 11000,
  //   capex: 3000,
  // },
  Level3at50: {
    name: "50kW (L3)",
    description:
      "A DC charging solution, either as a stall or wall-mounted unit with Type 2 (CCS) connectivity, typically linked to a high-amp circuit for charging at speeds up to 50kW.",
    maxPower: 50000,
    capex: 35000,
  },
  Level3at120: {
    name: "120kW (L3)",
    description:
      "A high-powered DC charging station, available in stall or wall-mounted configurations with Type 2 (CCS) compatibility, designed for a dedicated high-amp circuit to facilitate charging rates of up to 120kW",
    maxPower: 120000,
    capex: 60000,
  },
};
export type ChargerType = keyof typeof ChargerTypes;
export const DEFAULT_OVERNIGHT_CHARGE_DURATION_HOURS = 10;
export const DEFAULT_INTRADAY_MAX_CHARGING_TIME_HOURS = 2;
export const DEFAULT_INTRADAY_SLOTS_PER_CHARGER_PER_HOUR = 2;
