"use client";
import { LicenseInfo } from "@mui/x-license";

import { env } from "~/env";

LicenseInfo.setLicenseKey(env.NEXT_PUBLIC_MUI_X_LICENSE_KEY);

export default function MuiXLicense() {
  return null;
}
